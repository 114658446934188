import { getAxiosDataNode } from '@/services/apiConfig';
import { Result } from '@/types/services';
/*
* 接口类型：Post
* 编码类型：Content-Type:application/json
*/

// 获取企业服务
async function getEnterpriseServices(params = {}): Promise<any> {
  const url = 'enterprise/getEnterpriseServices';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}
// 获取是否有未注册用户添加全新 也可获取当前企业；
async function getLoginProcess(params = {}): Promise<any> {
  const url = 'enterprise/getLoginProcess';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}

// 获取企业服务绑定项目情况
async function getUserMidAndAoiCountByUserKey(params = {}): Promise<any> {
  const url = 'enterprise/getUserMidAndAoiCountByUserKey';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}

// 获取mall区域
async function getMallBySearch(params = {}): Promise<any> {
  const url = 'mall/getMallBySearch';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}

// 获取开平存储的企业信息
async function getUserInformation(params = {}): Promise<any> {
  const url = 'enterprise/getUserInformation';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}

// 获取企业日志
async function getUserKeyLog(params = {}): Promise<any> {
  const url = 'enterprise/getUserKeyLog';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}

// 获取企业绑定的竞品
async function getUserCmidByUserKeyAndMallId(params = {}): Promise<any> {
  const url = 'enterprise/getUserCmidByUserKeyAndMallId';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}

// 获取企业绑定的aoi
async function getAoiRegionByUserKeyAndMallId(params = {}): Promise<any> {
  const url = 'enterprise/getAoiRegionByUserKeyAndMallId';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}

// 修改企业服务信息
async function updateEnterpriseServices(params = {}): Promise<any> {
  const url = 'enterprise/updateEnterpriseServices';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}

// 修改企业服务信息
async function asyncUserKeyPermission(params = {}): Promise<any> {
  const url = 'enterprise/asyncUserKeyPermission';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}

// 企业服务项目提交接口
async function addUserKeyMidS(params = {}): Promise<any> {
  const url = 'enterprise/addUserKeyMidS';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}

// 未再开平注册是否可以开通权限
async function loginProcess(params = {}): Promise<any> {
  const url = 'enterprise/loginProcess';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}

// 增加日志
async function addUserKeyLog(params = {}): Promise<any> {
  const url = 'enterprise/addUserKeyLog';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}

// 增加企业服务
async function addEnterpriseServices(params = {}): Promise<any> {
  const url = 'enterprise/addEnterpriseServices';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}
export {
  getEnterpriseServices,
  getLoginProcess,
  getUserMidAndAoiCountByUserKey,
  getMallBySearch,
  getUserInformation,
  getUserKeyLog,
  getUserCmidByUserKeyAndMallId,
  getAoiRegionByUserKeyAndMallId,
  updateEnterpriseServices,
  addUserKeyMidS,
  loginProcess,
  addUserKeyLog,
  addEnterpriseServices,
  asyncUserKeyPermission,
};
