


















































































































/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-param-reassign */

import { Vue, Component } from 'vue-property-decorator';
import {
  getAoiRegionByUserKeyAndMallId,
  getUserCmidByUserKeyAndMallId,
} from '@/services/serviceApi';
import { CreateElement } from 'vue';
import { aoiType } from '@/config/global';
import { initDate } from '@/components/index';

@Component({
  components: {},
})
export default class UpdateMallService extends Vue {
  title = '企业服务管理-MALL项目功能权限配置';
  mallName = '';
  $dayjs: any;
  competeCountVal = 0;
  aoiCountVal = 0;
  competeCount = 0;
  aoiCount = 0;
  oldDataObj: any = {};
  newDataObj: any = {};
  tableHg = 203;
  data1: any = [];
  data2: any = [];
  loading1 = false;
  loading2 = false;
  competeObj: any = {};
  aoiObj: any = {};
  deleteCompeteObj: any = {};
  deleteAoiObj: any = {};
  competeDataObj: any = {};
  aoiDataObj: any = {};
  serviceCycles = [
    {
      service_cycle_start: initDate('d'),
      service_cycle_end: initDate('d'),
    },
  ];
  columnPage1 = [
    {
      title: 'mall_id',
      key: 'compete_mall_id',
      align: 'center',
    },
    {
      title: '项目名称',
      key: 'name',
      align: 'center',
    },
    {
      title: '省份',
      key: 'province',
      align: 'center',
    },
    {
      title: '城市',
      key: 'city',
      align: 'center',
    },
    {
      title: '区县',
      key: 'district',
      align: 'center',
    },
    {
      title: '编辑',
      key: 'operate',
      slot: 'operate',
      align: 'center',
    },
  ];
  columnPage2 = [
    {
      title: '区域ID',
      key: 'aoi_id',
      align: 'center',
    },
    {
      title: '区域名称',
      key: 'aoi_name',
      align: 'center',
    },
    {
      title: '区域类型',
      key: 'num_type',
      align: 'center',
      render: (h: CreateElement, params: any) => {
        const { num_type } = params.row;
        const text = aoiType[num_type];
        return h('div', {
          domProps: {
            innerText: text,
          },
        });
      },
    },
    {
      title: '申请用户',
      key: 'user',
      align: 'center',
    },
    {
      title: '申请用户手机号',
      key: 'tel',
      align: 'center',
    },
    {
      title: '编辑',
      key: 'operate',
      slot: 'operate',
      align: 'center',
    },
  ];
  pageClickHandler(
    row: any,
    userKey: string,
    en_key: string,
    competeDataObj: any,
    aoiDataObj: any,
  ): void {
    const {
      mall_id,
      name,
      serviceCycles,
      aoi_count,
      compete_count,
      lei,
    } = row;
    this.competeObj = {};
    this.aoiObj = {};
    this.deleteAoiObj = {};
    this.deleteCompeteObj = {};
    this.competeDataObj = competeDataObj[mall_id];
    this.aoiDataObj = aoiDataObj[mall_id];
    this.mallName = name;
    this.serviceCycles = serviceCycles;
    this.competeCountVal = Number(compete_count);
    this.aoiCountVal = Number(aoi_count);
    this.oldDataObj = row;
    this.newDataObj = row;
    const params = {
      user_key: userKey,
      en_key,
      mall_id,
    };
    const competData = Object.values(this.competeDataObj);
    if (competData.length !== 0 || lei === 'update') {
      competData.forEach((item: any) => {
        const { compete_mall_id } = item;
        this.competeObj[compete_mall_id] = item;
      });
      this.data1 = Object.values(this.competeObj);
      this.competeCount = this.data1.length;
    } else {
      this.getUserCmidByUserKeyAndMallId(params);
    }
    const aoiData = Object.values(this.aoiDataObj);
    if (aoiData.length !== 0) {
      aoiData.forEach((item: any) => {
        const { aoi_id } = item;
        this.aoiObj[aoi_id] = item;
      });
      this.data2 = Object.values(this.aoiObj);
      this.aoiCount = this.data2.length;
    } else {
      this.getAoiRegionByUserKeyAndMallId(params);
    }
  }
  deleteMallClickHandler(row: any): void {
    const { mall_id, compete_mall_id, name } = row;
    // if (!this.deleteCompeteObj[mall_id]) {
    //   this.deleteCompeteObj[mall_id] = {};
    // }
    // eslint-disable-next-line operator-linebreak
    const info =
      `mall_id:(${mall_id})` + `(配置竞品:-${compete_mall_id}(${name});`;
    this.deleteCompeteObj[compete_mall_id] = {
      mall_id,
      compete_mall_id,
      name,
      info,
    };
    delete this.competeObj[compete_mall_id];
    this.data1 = Object.values(this.competeObj);
    this.competeCount = this.data1.length;
  }
  deleteAoiClickHandler(row: any): void {
    const { mall_id, aoi_id, mall_name, aoi_name } = row;
    // if (!this.deleteAoiObj[mall_id]) {
    //   this.deleteAoiObj[mall_id] = {};
    // }
    const info = `mall_id:(${mall_id})` + `(删除AOI-${aoi_id}(${aoi_name});`;
    this.deleteAoiObj[aoi_id] = {
      mall_id,
      mall_name,
      aoi_name,
      aoi_id,
      info,
    };
    delete this.aoiObj[aoi_id];
    this.data2 = Object.values(this.aoiObj);
    this.aoiCount = this.data2.length;
  }
  async getUserCmidByUserKeyAndMallId(params: any): Promise<void> {
    this.loading1 = true;
    const res: any = await getUserCmidByUserKeyAndMallId(params);
    if (res.status === 0 || res.status === 2) {
      res.data.forEach((item: any) => {
        const { compete_mall_id } = item;
        this.competeObj[compete_mall_id] = item;
      });
      this.data1 = res.data.length === 0 ? [] : Object.values(this.competeObj);
    } else {
      this.data1 = [];
      const text = '查询异常，请联系管理员';
      this.$Modal.error({
        title: this.title,
        content: text,
        onOk: async () => {
          // this.$emit('viewServiceChange', false);
        },
      });
    }
    this.competeCount = this.data1.length;
    this.loading1 = false;
  }

  async getAoiRegionByUserKeyAndMallId(params: any): Promise<void> {
    this.loading2 = true;
    const res: any = await getAoiRegionByUserKeyAndMallId(params);
    if (res.status === 0 || res.status === 2) {
      res.data.forEach((item: any) => {
        const { aoi_id } = item;
        this.aoiObj[aoi_id] = item;
      });
      this.data2 = res.data.length === 0 ? [] : Object.values(this.aoiObj);
    } else {
      this.data2 = [];
      const text = '查询异常，请联系管理员';
      this.$Modal.error({
        title: this.title,
        content: text,
        onOk: async () => {
          // this.$emit('viewServiceChange', false);
        },
      });
    }
    this.aoiCount = this.data2.length;
    this.loading2 = false;
  }
  cancelClickHandler(): void {
    const text = '取消后配置将不会生效，请确认或取消';
    this.$Modal.confirm({
      title: this.title,
      content: text,
      onOk: async () => {
        this.$emit('cancelChange');
      },
    });
  }
  confirmClickHandler(): void {
    if (this.competeCountVal < this.competeCount || this.aoiCountVal < this.aoiCount) {
      let text1 = '';
      if (this.competeCountVal < this.competeCount) {
        text1 = '可添加竞品个数不能小于已添加竞品个数';
      }
      if (this.aoiCountVal < this.aoiCount) {
        if (text1) {
          text1 = `${text1};\n`;
        }
        text1 = `${text1}可申请客流来源区域个数不能小于已添加客流来源区域个数`;
      }
      this.$Modal.warning({
        title: this.title,
        content: text1,
      });
      return;
    }
    const tempObj: any = {};
    for (const item of this.serviceCycles) {
      const { service_cycle_start, service_cycle_end } = item;
      if (!service_cycle_start || !service_cycle_end) {
        this.$Message.warning('请填写完整的服务周期');
        return;
      }
      if (tempObj[service_cycle_start] === service_cycle_end) {
        this.$Message.warning('服务周期重复，请检查');
        return;
      }
      tempObj[service_cycle_start] = service_cycle_end;
    }

    const text = '确认后，配置将会局部生效，等到外部提交后，配置会全部生效，如操作失误请取消，如正确请点击确认';
    this.$Modal.confirm({
      title: this.title,
      content: text,
      onOk: async () => {
        const aoiInfo = `可添加${this.aoiCountVal},已添加${this.aoiCount}`;
        const competeInfo = `可添加${this.competeCountVal},已添加${this.competeCount}`;
        this.newDataObj.name = this.mallName;
        const serviceCycles = this.serviceCycles.map((item: any) => `${this.$dayjs(item.service_cycle_start).format('YYYY/MM/DD')}-${this.$dayjs(item.service_cycle_end).format('YYYY/MM/DD')}`);
        const serviceCycle = serviceCycles.join('\n');
        this.newDataObj.service_cycle = serviceCycle;
        this.newDataObj.serviceCycles = this.serviceCycles;
        this.newDataObj.compete_count = this.competeCountVal;
        this.newDataObj.aoi_count = this.aoiCountVal;
        this.newDataObj.compete_info = competeInfo;
        this.newDataObj.aoi_info = aoiInfo;
        this.newDataObj.add_competed_count = this.competeCount;
        this.newDataObj.add_aoi_count = this.aoiCount;
        this.newDataObj.lei = 'update';
        const { mall_id } = this.newDataObj;
        const obj: any = {};
        obj[mall_id] = this.newDataObj;
        this.$emit(
          'confirmChange',
          obj,
          this.deleteCompeteObj,
          this.deleteAoiObj,
          this.competeObj,
          this.aoiObj,
          mall_id,
        );
      },
    });
  }
  startDateChangeHandler(dates: string, item: any): void {
    const date = this.$dayjs(dates).format('YYYY-MM-DD');
    if (item.service_cycle_end && date > item.service_cycle_end) {
      this.$Message.error('开始日期不能大于结束日期');
      item.service_cycle_start = '';
      return;
    }
    item.service_cycle_start = date;
  }

  endDateChangeHandler(dates: string, item: any): void {
    const date = this.$dayjs(dates).format('YYYY-MM-DD');
    if (item.service_cycle_start && date < item.service_cycle_start) {
      this.$Message.error('开始日期不能大于结束日期');
      item.service_cycle_end = '';
      return;
    }
    item.service_cycle_end = date;
  }
  addDate() {
    this.serviceCycles.push({
      service_cycle_start: initDate('d'),
      service_cycle_end: initDate('d'),
    });
  }
  deleteDate() {
    if (this.serviceCycles.length === 1) {
      return;
    }
    this.serviceCycles.pop();
  }
}
