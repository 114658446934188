





















































































































































































































import {
  addEnterpriseServices,
  addUserKeyLog,
  addUserKeyMidS,
  getEnterpriseServices,
  getLoginProcess,
  getUserInformation,
  getUserKeyLog,
  getUserMidAndAoiCountByUserKey,
  loginProcess,
  updateEnterpriseServices,
} from '@/services/serviceApi';
import { Vue, Component } from 'vue-property-decorator';
import addMallService from '@/components/addMallService/index.vue';
import updateMallService from '@/components/updateMallService/index.vue';
import { CreateElement } from 'vue';
import { isKey, isXss } from '@/components/map/map';
@Component({
  components: {
    addMallService,
    updateMallService,
  },
})
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable operator-linebreak */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/dot-notation */
export default class ViewService extends Vue {
  $dayjs: any;
  $domainConfig: any;
  keyVal = '';
  en_key = '';
  name = '';
  coName = '';
  serviceName = '';
  businessManager = '';
  salesCounterpart = '';
  seviceCycleStart = '';
  seviceCycleEnd = '';
  person = '';
  placeHolder = '必填项';
  userPrivlege = false;
  initUserPrivlege = false;
  servicesLoading = false;
  logLoading = false;
  servicePageStatus = false;
  serviceInfoStatus = false;
  serviceDisabled = true;
  wrapStatus = true;
  servicesData: any = [];
  logData: any = [];
  mallObj: any = {};
  clearTime: any = null;
  competeDataObj: any = {};
  aoiDataObj: any = {};
  initServices: any = {};
  mallCount = 0;
  initTrHg = 50;
  initTableHg = 203;
  type = '';
  tableHg = this.initTableHg;
  deleteCompeteObj: any = {};
  deleteAoiObj: any = {};
  title = '企业服务管理-服务管理';
  addMallServiceStatus = false;
  updateMallServiceStatus = false;
  initMallObj: any = {};
  disabled = false;
  mallLogInfo: any = {
    0: '暂无变化',
    1: '添加MALL项目',
    2: {
      compete_count: '更新竞品使用详情',
      aoi_count: '更新客流来源使用详情',
      service_cycle_start: '更新项目登录访问周期开始时间',
      service_cycle_end: '更新项目登录访问周期结束时间',
      serviceCycles: '更新MALL项目周期',
    },
    3: {
      mall: {
        text: '删除MALL项目',
        name: 'name',
      },
      aoi: {
        text: '删除AOI',
        name: 'aoi_name',
      },
    },
  };
  columnLogPage = [
    {
      title: '创建时间',
      key: 'datetime',
      align: 'center',
    },
    {
      title: '操作人',
      key: 'operator',
      align: 'center',
    },
    {
      title: '配置内容',
      key: 'text',
      align: 'center',
      render: (h: CreateElement, params: any) => {
        const { text } = params.row;
        const ary = text.split(';');
        let texts = '';
        ary.forEach((item: string) => {
          texts += `${item}<br>`;
        });
        return h('div', {
          domProps: {
            innerHTML: texts,
          },
        });
      },
    },
  ];
  columnPage = [
    {
      title: '类型',
      key: 'take',
      align: 'center',
      width: 80,
      render: (h: CreateElement, params: any) => {
        let { take } = params.row;
        if (!take) {
          take = '新增';
        }
        return h('div', {
          domProps: {
            innerText: take,
          },
        });
      },
    },
    {
      title: 'mall_id',
      key: 'mall_id',
      align: 'center',
      width: 100,
    },
    {
      title: '项目名称',
      key: 'name',
      align: 'center',
      width: 200,
    },
    {
      title: '省份',
      key: 'province',
      align: 'center',
      width: 100,
    },
    {
      title: '城市',
      key: 'city',
      align: 'center',
      width: 130,
    },
    {
      title: '区县',
      key: 'district',
      align: 'center',
      width: 100,
    },
    {
      title: '服务周期',
      key: 'service_cycle',
      align: 'center',
      width: 210,
    },
    {
      title: '竞品使用详情',
      key: 'compete_info',
      align: 'center',
    },
    {
      title: '客流来源使用详情',
      key: 'aoi_info',
      align: 'center',
    },
    {
      title: '权限',
      key: 'operate',
      slot: 'operate',
      align: 'center',
    },
  ];
  typeKeyObj = {
    add: 1,
    update: 2,
    delete: 3,
  };
  serviceKeyObj: any = {
    service_name: '企业服务名称',
    customer_business_contact_person: '企业服务名称',
    business_manager: '腾讯商务对接人',
    sales_counterpart: '腾讯销售对接人',
    service_cycle_start: '登录访问周期开始时间',
    service_cycle_end: '登录访问周期结束时间',
  };
  returnPage(): void {
    const text = '返回后，配置将不会生效，请取消或确认';
    this.$Modal.confirm({
      title: this.title,
      content: text,
      onOk: async () => {
        this.$emit('viewServiceChange', false);
      },
    });
  }
  servicePageClickHandler(): void {
    this.servicePageStatus = true;
  }
  key1UpNavictveHandler(e: any): void {
    if (this.clearTime) clearTimeout(this.clearTime);
    this.clearTime = setTimeout(() => {
      this.keyVal = isXss(e.target.value);
    }, 0);
  }
  key2UpNavictveHandler(e: any): void {
    if (this.clearTime) clearTimeout(this.clearTime);
    this.clearTime = setTimeout(() => {
      this.serviceName = isXss(e.target.value);
    }, 0);
  }
  key3UpNavictveHandler(e: any): void {
    if (this.clearTime) clearTimeout(this.clearTime);
    this.clearTime = setTimeout(() => {
      this.person = isXss(e.target.value);
    }, 0);
  }
  key4UpNavictveHandler(e: any): void {
    if (this.clearTime) clearTimeout(this.clearTime);
    this.clearTime = setTimeout(() => {
      this.businessManager = isXss(e.target.value);
    }, 0);
  }
  key5UpNavictveHandler(e: any): void {
    if (this.clearTime) clearTimeout(this.clearTime);
    this.clearTime = setTimeout(() => {
      this.salesCounterpart = isXss(e.target.value);
    }, 0);
  }
  serviceInfoClickHandler(): void {
    if (!this.keyVal || !isKey(this.keyVal)) {
      const text = 'key格式错误/或者不能为空';
      this.$Modal.error({
        title: this.title,
        content: text,
        onOk: async () => {
          console.log(text);
        },
      });
      return;
    }
    this.serviceInfoStatus = true;
    const params1 = {
      local: this.$domainConfig.local,
      user_key: this.keyVal,
      en_key: this.en_key,
    };
    this.getUserInformation(params1);
    const params = {
      page: 1,
      rows: 10,
      search_type: 3,
      search_str: this.keyVal,
    };
    this.getEnterpriseServices(params);
  }
  async getEnterpriseServices(params: any): Promise<void> {
    const res = await getEnterpriseServices(params);
    const text =
      '该key已在服务中，请勿在添加企业服务操作,如需对key进行配置，请再企业服务管理首页，搜索该key进行服务配置';
    if (res.status === 0 && res.data.length !== 0) {
      this.serviceDisabled = true;
      this.$Modal.info({
        title: this.title,
        content: text,
        okText: '知道啦',
        onOk: async () => {
          console.log(text);
        },
      });
      return;
    }
    this.serviceDisabled = false;
  }
  userPrivlegeChangeHandler(status: any): void {
    this.userPrivlege = status;
  }
  async pageClickHandler(
    row: any,
    disabled: boolean,
    type: string,
  ): Promise<void> {
    this.type = type;
    this.servicePageStatus = type === 'update';
    this.competeDataObj = {};
    this.aoiDataObj = {};
    this.deleteCompeteObj = {};
    this.deleteAoiObj = {};
    this.servicesData = [];
    this.logData = [];
    this.mallObj = {};
    this.initServices = {};
    this.initMallObj = {};
    this.tableHg = this.initTableHg;
    this.disabled = disabled;
    this.keyVal = '';
    this.en_key = '';
    this.serviceName = '';
    this.businessManager = '';
    this.person = '';
    this.salesCounterpart = '';
    this.mallCount = 0;
    if (type !== 'update') {
      return;
    }
    const {
      business_manager: businessManager,
      customer_business_contact_person: person,
      mall_count: mallCount,
      sales_counterpart: salesCounterpart,
      service_name: serviceName,
      user_key: userKey,
      en_key,
      service_cycle_start: seviceCycleStart,
      service_cycle_end: seviceCycleEnd,
    } = row;
    this.initServices = {
      business_manager: businessManager,
      customer_business_contact_person: person,
      mall_count: mallCount,
      sales_counterpart: salesCounterpart,
      service_name: serviceName,
      user_key: userKey,
      service_cycle_start: seviceCycleStart || '',
      service_cycle_end: seviceCycleEnd || '',
    };
    this.serviceName = serviceName;
    this.keyVal = userKey;
    this.en_key = en_key;
    this.businessManager = businessManager;
    this.person = person;
    this.salesCounterpart = salesCounterpart;
    this.seviceCycleStart = seviceCycleStart || '';
    this.seviceCycleEnd = seviceCycleEnd || '';
    this.mallCount = mallCount;
    const params = {
      en_key: row.en_key,
    };
    this.getLoginProcess(params);
    this.getUserMidAndAoiCountByUserKey(params);
    const params1 = {
      local: this.$domainConfig.local,
      user_key: this.keyVal,
      en_key: this.en_key,
    };
    this.getUserInformation(params1);
    this.getUserKeyLog(params);
  }
  async getLoginProcess(params: any): Promise<void> {
    const res: any = await getLoginProcess(params);
    if (res.status === 0 || res.status === 2) {
      this.userPrivlege = res.data.length > 0;
    } else {
      this.userPrivlege = false;
      this.$Modal.error({
        title: this.title,
        content: '未注册用户添加权限接口获取失败error',
        onOk: async () => {
          // console.log(2);
        },
      });
    }
    this.initUserPrivlege = this.userPrivlege;
  }

  async getUserKeyLog(params: any): Promise<void> {
    const res: any = await getUserKeyLog(params);
    if (res.status === 0 || res.status === 2) {
      // const { datetime, operator, text } = res.data;
      this.logData = res.data;
    } else {
      this.$Modal.error({
        title: this.title,
        content: '日志信息查询异常，请联系管理员',
        onOk: async () => {
          // console.log(2);
        },
      });
    }
  }

  async getUserInformation(params: any): Promise<void> {
    const res: any = await getUserInformation(params);
    if (res.status === 0) {
      const { co_name, name } = res.data;
      this.coName = co_name;
      this.name = name;
    } else {
      this.$Modal.error({
        title: this.title,
        content: '企业信息查询异常，请联系管理员',
        onOk: async () => {
          // console.log(2);
        },
      });
    }
  }
  cancelChange(): void {
    this.wrapStatus = true;
    this.addMallServiceStatus = false;
    this.updateMallServiceStatus = false;
  }
  cancelClickHandler(): void {
    const text = '取消后配置将不会生效，请确认或取消';
    this.$Modal.confirm({
      title: this.title,
      content: text,
      onOk: async () => {
        this.$emit('viewServiceChange', false);
      },
    });
  }

  confirmClickHandler(): void {
    if (
      !this.keyVal ||
      !this.person ||
      !this.businessManager ||
      !this.salesCounterpart ||
      !this.seviceCycleStart ||
      !this.seviceCycleEnd
    ) {
      const text = '企业服务相关信息不能为空，请填写';
      this.$Modal.warning({
        title: this.title,
        content: text,
        onOk: async () => {
          console.log(text);
        },
      });
      return;
    }
    const text = '确认提交后，配置将会生效';
    this.$Modal.confirm({
      title: this.title,
      content: text,
      onOk: async () => {
        this.submitTakeClickHandler();
      },
    });
  }
  async submitTakeClickHandler(): Promise<void> {
    const serviceObj: any = {
      user_key: this.keyVal,
      en_key: this.en_key,
      service_name: this.serviceName,
      // service_info: this.coName,
      // customer_name: this.name,
      business_manager: this.businessManager,
      customer_business_contact_person: this.person,
      sales_counterpart: this.salesCounterpart,
      service_cycle_start: this.seviceCycleStart,
      service_cycle_end: this.seviceCycleEnd,
    };
    let updateStatus1 = false;
    let serviceInfo = '';
    for (const key in serviceObj) {
      if (
        Object.prototype.hasOwnProperty.call(serviceObj, key) &&
        Object.prototype.hasOwnProperty.call(this.initServices, key)
      ) {
        const el1 = serviceObj[key];
        const el2 = this.initServices[key];
        if (el1 !== el2) {
          serviceInfo += `${this.serviceKeyObj[key]},更新前:(${el2}),更新后:(${el1});`;
          updateStatus1 = true;
        }
      }
    }

    if (updateStatus1 === true) {
      // const sparams = {
      //   service_obj: serviceObj,
      // };
      if (this.type === 'update') {
        this.updateEnterpriseServices(serviceObj);
      }
    }
    if (this.type === 'add') {
      serviceObj['service_info'] = this.coName;
      serviceObj['customer_name'] = this.name;
      // const params = {
      //   service_obj: serviceObj,
      // };
      this.addEnterpriseServices(serviceObj);
    }
    let loginProcessStatus = false;
    let loginProcessInfo = '';
    if (this.initUserPrivlege !== this.userPrivlege) {
      loginProcessStatus = true;
    }
    const submitObj = this.processDataBySubmit();
    const { mallObj } = submitObj;
    let mallInfo = submitObj.infos;
    const malls = Object.keys(mallObj);
    const competes = Object.keys(this.deleteCompeteObj);
    const aois = Object.keys(this.deleteAoiObj);
    let competeInfo = '';
    for (const key in this.deleteCompeteObj) {
      if (Object.prototype.hasOwnProperty.call(this.deleteCompeteObj, key)) {
        const item = this.deleteCompeteObj[key];
        for (const key2 in item) {
          if (Object.prototype.hasOwnProperty.call(item, key2)) {
            const item2 = item[key2];
            competeInfo += item2.info;
          }
        }
      }
    }
    let aoiInfo = '';
    for (const key in this.deleteAoiObj) {
      if (Object.prototype.hasOwnProperty.call(this.deleteAoiObj, key)) {
        const item = this.deleteAoiObj[key];
        for (const key2 in item) {
          if (Object.prototype.hasOwnProperty.call(item, key2)) {
            const item2 = item[key2];
            aoiInfo += item2.info;
          }
        }
      }
    }
    if (
      malls.length === 0 &&
      competes.length === 0 &&
      aois.length === 0 &&
      !updateStatus1 &&
      !loginProcessStatus &&
      this.type !== 'add'
    ) {
      const text = '暂无信息更改';
      if (this.clearTime) clearTimeout(this.clearTime);
      this.clearTime = setTimeout(() => {
        this.$Modal.info({
          title: this.title,
          content: text,
          onOk: async () => {
            console.log(text);
            // this.$emit('viewServiceChange', false);
          },
        });
      }, 300);
      return;
    }
    const typeStr = this.type === 'add' ? '新增' : '更新';
    if (loginProcessStatus) {
      const text = this.userPrivlege ? '是' : '否';
      loginProcessInfo = `未注册用户是否可以添加权限:${text};`;
    }
    if (serviceInfo || loginProcessStatus) {
      serviceInfo = `${typeStr}企业服务信息:;${serviceInfo}`;
      serviceInfo += loginProcessInfo;
    }
    if (mallInfo) {
      mallInfo = `${typeStr}企业服务项目:;${mallInfo};`;
    }
    if (competeInfo) {
      competeInfo = `删除竞品项目:;${competeInfo}`;
    }
    if (aoiInfo) {
      aoiInfo = `删除aoi项目:;${aoiInfo}`;
    }
    const logInfo = serviceInfo + mallInfo + competeInfo + aoiInfo;
    const data: any = {};
    data['user_key'] = this.keyVal;
    data['en_key'] = this.en_key;
    data['user_obj'] = mallObj;
    data['competed_submit_obj'] = this.deleteCompeteObj;
    data['aoi_submit_obj'] = this.deleteAoiObj;
    let addUserKeySuccess = false;
    if (this.clearTime) clearTimeout(this.clearTime);
    if (malls.length !== 0 || competes.length !== 0 || aois.length !== 0) {
      const res: any = await this.addUserKeyMidS(data);
      if (res.status === 0) {
        addUserKeySuccess = true;
      } else {
        const text =
          this.type === 'update' ? '修改企业服务失败' : '添加企业服务失败';
        this.clearTime = setTimeout(() => {
          this.$Modal.error({
            title: this.title,
            content: text,
            onOk: async () => {
              this.$emit('viewServiceChange', false);
            },
          });
        }, 1000);
        return;
      }
    }
    if (addUserKeySuccess || this.type === 'add' || updateStatus1) {
      const params = {
        user_key: this.keyVal,
        en_key: this.en_key,
        text: logInfo,
      };
      this.addUserKeyLog(params);
    }
    if (loginProcessStatus) {
      const status = this.userPrivlege ? 1 : 0;
      const params1 = {
        user_key: this.keyVal,
        status,
      };
      this.loginProcess(params1);
    }
    const text =
      this.type === 'update' ? '修改企业服务成功' : '添加企业服务成功';
    this.clearTime = setTimeout(() => {
      this.$Modal.success({
        title: this.title,
        content: text,
        onOk: async () => {
          this.$emit('viewServiceChange', false);
        },
      });
    }, 1000);
    //
  }
  async addUserKeyLog(params: any): Promise<void> {
    const res = await addUserKeyLog(params);
  }

  async loginProcess(params: any): Promise<void> {
    const res = await loginProcess(params);
  }

  processDataBySubmit() {
    const { typeKeyObj } = this;
    const submitObj: any = {};
    const field = 'mall';
    // delete update 机制触发函数
    for (const key in this.initMallObj) {
      // 初始化区域容器，和编辑修改删除容器
      const initItem = this.initMallObj[key];
      if (
        Object.prototype.hasOwnProperty.call(this.initMallObj, key) &&
        Object.prototype.hasOwnProperty.call(this.mallObj, key)
      ) {
        const item = this.mallObj[key];
        let info = '';
        // 是否有更新，更新机制触发
        for (const uKey in initItem) {
          if (Object.prototype.hasOwnProperty.call(initItem, uKey)) {
            // 可以更新的字段
            if (
              uKey === 'compete_count' ||
              uKey === 'aoi_count'
            ) {
              const initChildernItem = initItem[uKey];
              const childernItem = item[uKey];
              if (String(initChildernItem) !== String(childernItem)) {
                // 是否有更新字段
                const type = typeKeyObj.update;
                // 更新日志存储
                const mallLogItem = this.mallLogInfo[type];
                const infos =
                  `${mallLogItem[uKey]}-` +
                  `更新前：(${initChildernItem}),` +
                  `更新后：(${childernItem}),`;
                this.mallObj[key]['type'] = type;
                info += infos;
              }
            }
            // 可以更新的字段
            if (uKey === 'serviceCycles') {
              const initChildernItem = initItem[uKey];
              const initChildernItemServiceCycles = initChildernItem.map((item: any) => `${this.$dayjs(item.service_cycle_start).format('YYYY-MM-DD')}-${this.$dayjs(item.service_cycle_end).format('YYYY-MM-DD')}`);
              const initChildernItemServiceCycle = initChildernItemServiceCycles.join(';');

              const childernItem = item[uKey];
              const childernItemServiceCycles = childernItem.map((item: any) => `${this.$dayjs(item.service_cycle_start).format('YYYY-MM-DD')}-${this.$dayjs(item.service_cycle_end).format('YYYY-MM-DD')}`);
              const childernItemServiceCycle = childernItemServiceCycles.join(';');

              if (initChildernItemServiceCycle !== childernItemServiceCycle) {
                // 是否有更新字段
                const type = typeKeyObj.update;
                // 更新日志存储
                const mallLogItem = this.mallLogInfo[type];
                const infos =
                  `${mallLogItem[uKey]}-` +
                  `更新前：(${initChildernItemServiceCycle}),` +
                  `更新后：(${childernItemServiceCycle}),`;
                this.mallObj[key]['type'] = type;
                info += infos;
              }
            }
          }
        }
        submitObj[key] = item;
        submitObj[key]['info'] = `${initItem.name}(${info});`;
      } else {
        // 删除机制触发
        const type = typeKeyObj.delete;
        const mallLogItem = this.mallLogInfo[type];
        submitObj[key] = initItem;
        submitObj[key]['type'] = type;
        submitObj[key]['info'] = `${mallLogItem[field].text}-${key}(${
          initItem[mallLogItem[field].name]
        });`;
      }
    }

    // add 机制触发函数
    for (const key in this.mallObj) {
      if (
        !Object.prototype.hasOwnProperty.call(this.initMallObj, key) &&
        Object.prototype.hasOwnProperty.call(this.mallObj, key)
      ) {
        const mallItem = this.mallObj[key];
        const type = typeKeyObj.add;
        const mallLogItem = this.mallLogInfo[type];
        submitObj[key] = mallItem;
        submitObj[key]['type'] = type;
        submitObj[key][
          'info'
        ] = `${mallLogItem}-${key}(${this.mallObj[key].name});`;
      }
    }

    const obj: any = {};
    let infos = '';
    for (const key in submitObj) {
      if (Object.prototype.hasOwnProperty.call(submitObj, key)) {
        const item = submitObj[key];
        if (item.type) {
          const {
            mall_id,
            type,
            add_aoi_count,
            add_competed_count,
            aoi_count,
            compete_count,
            service_cycle,
            serviceCycles,
            // service_cycle_start,
            // service_cycle_end,
            info,
          } = item;
          obj[key] = {};
          obj[key]['mall_id'] = mall_id;
          obj[key]['type'] = type;
          obj[key]['add_aoi_count'] = add_aoi_count;
          obj[key]['add_competed_count'] = add_competed_count;
          obj[key]['aoi_count'] = aoi_count;
          obj[key]['compete_count'] = compete_count;
          obj[key]['service_cycle'] = service_cycle;
          obj[key]['serviceCycles'] = serviceCycles;
          infos += info;
        }
      }
    }
    return {
      mallObj: obj,
      infos,
    };
  }
  // addEnterpriseServices
  async addEnterpriseServices(params: any): Promise<void> {
    const res = await addEnterpriseServices(params);
    if (res.status === 0) {
      console.log('企业服务增加成功');
      // const text = res.info;
      // this.$Modal.success({
      //   title: this.title,
      //   content: text,
      //   onOk: async () => {
      //     console.log('成功');
      //   },
      // });
    } else {
      const text = '增加异常，请联系管理员';
      this.$Modal.error({
        title: this.title,
        content: text,
        onOk: async () => {
          console.log('异常');
        },
      });
    }
  }
  async updateEnterpriseServices(params: any): Promise<void> {
    const res = await updateEnterpriseServices(params);
    if (res.status === 0) {
      console.log('企业服务修改成功');
      // const text = res.info;
      // this.$Modal.success({
      //   title: this.title,
      //   content: text,
      //   onOk: async () => {
      //     console.log('成功');
      //   },
      // });
    } else {
      const text = '修改异常，请联系管理员';
      this.$Modal.error({
        title: this.title,
        content: text,
        onOk: async () => {
          console.log('异常');
        },
      });
    }
  }
  async addUserKeyMidS(params: any) {
    const res = await addUserKeyMidS(params);
    return res;
  }

  updateServiceClickHandler(row: any): void {
    this.updateMallServiceStatus = true;
    const { mall_id } = row;
    if (!this.competeDataObj[mall_id]) {
      this.competeDataObj[mall_id] = {};
    }
    if (!this.aoiDataObj[mall_id]) {
      this.aoiDataObj[mall_id] = {};
    }
    (this.$refs.updateMallService as any).pageClickHandler(
      row,
      this.keyVal,
      this.en_key,
      this.competeDataObj,
      this.aoiDataObj,
    );
    this.wrapStatus = false;
  }

  deleteServiceClickHandler(row: any): void {
    const { mall_id } = row;
    delete this.mallObj[mall_id];
    this.servicesData = Object.values(this.mallObj);
    this.mallCount = this.servicesData.length;
  }
  confirmChange(mallData: any): void {
    // const mallObj = mallData.reduce((accumulator: any, current: any) => {
    //   accumulator[current.mall_id] = current;
    //   return accumulator;
    // }, {});
    this.wrapStatus = true;
    const mallObj: any = {};
    mallData.forEach((item: any) => {
      mallObj[item.mall_id] = item;
    });
    this.mallObj = Object.assign(this.mallObj, mallObj);
    this.servicesData = Object.values(this.mallObj);
    this.mallCount = this.servicesData.length;
  }
  uConfirmChange(
    mallObj: any,
    deleteCompeteObj: any,
    deleteAoiObj: any,
    competeDataObj: any,
    aoiDataObj: any,
    mall_id: number,
  ): void {
    this.mallObj = Object.assign(this.mallObj, mallObj);
    this.servicesData = Object.values(this.mallObj);
    this.wrapStatus = true;
    this.updateMallServiceStatus = false;
    this.competeDataObj[mall_id] = competeDataObj;
    this.aoiDataObj[mall_id] = aoiDataObj;
    this.deleteCompeteObj[mall_id] = deleteCompeteObj;
    this.deleteAoiObj[mall_id] = deleteAoiObj;
    console.log(
      deleteCompeteObj,
      deleteAoiObj,
      this.deleteCompeteObj,
      this.deleteAoiObj,
    );
  }
  addMallClickHandler(): void {
    this.wrapStatus = false;
    this.addMallServiceStatus = true;
  }
  async getUserMidAndAoiCountByUserKey(params: any): Promise<void> {
    this.servicesLoading = true;
    const res: any = await getUserMidAndAoiCountByUserKey(params);
    this.servicesLoading = false;
    this.servicesData = [];
    if (res.status === 0 || res.status === 2) {
      this.mallObj = {};
      this.initMallObj = {};
      res.data.forEach((item: any) => {
        const {
          mall_id,
          name,
          province,
          city,
          district,
          serviceCycles,
          aoi_count,
          compete_count,
          add_competed_count,
          add_aoi_count,
        } = item;
        const serviceCyclesTexts = serviceCycles.map((el: any) => {
          const { service_cycle_start,  service_cycle_end } = el;
          return `${this.$dayjs(service_cycle_start).format('YYYY/MM/DD')}-${this.$dayjs(service_cycle_end).format('YYYY/MM/DD')}`;
        });
        const aoiInfo = `可添加${aoi_count},  已添加${add_aoi_count}`;
        const competeInfo = `可添加${compete_count},  已添加${add_competed_count}`;
        const take = '已添加';
        const obj = {
          take,
          mall_id,
          name,
          province,
          city,
          district,
          service_cycle: serviceCyclesTexts.join('\n'),
          serviceCycles,
          aoi_count,
          compete_count,
          add_competed_count,
          add_aoi_count,
          aoi_info: aoiInfo,
          compete_info: competeInfo,
        };
        this.mallObj[mall_id] = obj;
        this.initMallObj[mall_id] = obj;
      });
      this.servicesData = Object.values(this.mallObj);
    } else {
      this.$Modal.error({
        title: this.title,
        content: '获取企业服务失败',
        onOk: async () => {
          // console.log(2);
        },
      });
    }
  }

  startDateChangeHandler(dates: string): void {
    const date = this.$dayjs(dates).format('YYYY-MM-DD');
    if (this.seviceCycleEnd && date > this.seviceCycleEnd) {
      this.$Message.error('开始日期不能大于结束日期');
      this.seviceCycleStart = '';
      return;
    }
    this.seviceCycleStart = date;
  }
  endDateChangeHandler(dates: string): void {
    const date = this.$dayjs(dates).format('YYYY-MM-DD');
    if (this.seviceCycleStart && date < this.seviceCycleStart) {
      this.$Message.error('开始日期不能大于结束日期');
      this.seviceCycleEnd = '';
      return;
    }
    this.seviceCycleEnd = date;
  }
}
