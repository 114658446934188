








































































import { Vue, Component } from 'vue-property-decorator';
import { getEnterpriseServices } from '@/services/serviceApi';
import { CreateElement } from 'vue';
import viewService from './viewService/index.vue';
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
@Component({
  components: {
    viewService,
  },
})
export default class Service extends Vue {
  querylist = [
    {
      label: '请选择',
      value: 0,
    },
    {
      label: '企业服务名称',
      value: 1,
    },
    {
      label: '企业key',
      value: 3,
    },
    {
      label: '客户姓名',
      value: 4,
    },
    {
      label: '腾讯商务经理',
      value: 5,
    },
  ];
  servicesLoading = false;
  searchKeyModal = false;
  searchValueModal = false;
  searchVal = '';
  current = 1;
  searchKey = 0;
  regionStatus = 3;
  viewServiceShow = false;
  total = 0;
  title = '企业服务管理';
  columnPage = [
    {
      title: '企业服务名称',
      key: 'service_name',
      align: 'center',
    },
    {
      title: '企业key',
      key: 'user_key',
      align: 'center',
    },
    {
      title: '客户姓名',
      key: 'customer_name',
      align: 'center',
      width: 160,
    },
    {
      title: '腾讯商务经理',
      key: 'business_manager',
      align: 'center',
      width: 130,
    },
    {
      title: '登录服务周期',
      key: 'service_cycle_start',
      align: 'center',
      width: 220,
      render: (h: CreateElement, params: any) => {
        const { service_cycle_start, service_cycle_end } = params.row;
        if (!service_cycle_start || !service_cycle_end) {
          return null;
        }
        const startDate = this.$dayjs(service_cycle_start).format('YYYY/MM/DD');
        const endDate = this.$dayjs(service_cycle_end).format('YYYY/MM/DD');
        return h('div', {
          domProps: {
            innerText: `${startDate}-${endDate}`,
          },
        });
      },
    },
    {
      title: '服务状态',
      key: 'status',
      align: 'center',
      width: 100,
      render: (h: CreateElement, params: any) => {
        const { service_cycle_start, service_cycle_end } = params.row;
        const startDate = this.$dayjs(service_cycle_start);
        const endDate = this.$dayjs(service_cycle_end);
        const targetDate = this.$dayjs();
        let text = '暂停服务';
        if (targetDate.isAfter(startDate) && targetDate.isBefore(endDate)) {
          text = '服务中';
        }
        return h('div', {
          domProps: {
            innerText: text,
          },
        });
      },
    },
    {
      title: '关联项目数量',
      key: 'mall_count',
      align: 'center',
      width: 140,
    },
    {
      title: '创建时间',
      key: 'create_time',
      align: 'center',
      width: 200,
    },
    {
      title: '操作',
      key: 'operate',
      slot: 'operate',
      align: 'center',
      width: 200,
    },
  ];
  servicesData = [];
  modalText = '';
  modalStatus = false;
  deleteText = '是否要删除区域';
  rowData: any = {};
  aoiNameIpt = '';
  aoiId = '';

  async mounted(): Promise<void> {
    const val = 1;
    this.pageChange(val);
  }

  reset(): void {
    // if(this/)
    this.searchKey = 0;
    this.regionStatus = 3;
    this.searchVal = '';
    this.current = 1;
    this.pageChange(this.current);
  }

  resetPage(): void {
    this.pageChange(this.current);
  }

  async searchClickHandler(): Promise<void> {
    if (!this.searchKey) {
      this.searchKeyModal = true;
      return;
    }
    if (!this.searchVal) {
      this.searchValueModal = true;
      return;
    }
    this.pageChange(1);
  }
  async pageChange(val: number): Promise<void> {
    this.servicesLoading = true;
    const params = {
      page: val,
      rows: 10,
      search_type: this.searchKey,
      search_str: this.searchVal,
    };
    const res: any = await this.getEnterpriseServices(params);
    this.servicesData = res.data;
    this.total = Number(res.total);
    this.current = val;
    this.servicesLoading = false;
  }

  async getEnterpriseServices(params: any): Promise<void> {
    const data = await getEnterpriseServices(params);
    return data;
  }

  viewService(row: any): void {
    this.viewServiceShow = true;
    (this.$refs.viewService as any).pageClickHandler(row, true, 'update');
  }

  viewServiceChange(status: boolean): void {
    this.viewServiceShow = status;
    this.pageChange(this.current);
  }

  addService(): void {
    this.viewServiceShow = true;
    (this.$refs.viewService as any).pageClickHandler({}, false, 'add');
  }

  regionChangeHandler(params: any): void {
    const { value } = params;
    this.regionStatus = value;
    this.pageChange(1);
  }
}
