


































































































































/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */

import { Vue, Component, Prop } from 'vue-property-decorator';
import { getMallBySearch } from '@/services/serviceApi';
import { initDate } from '@/components/index';

@Component({
  components: {},
})
export default class AddMallService extends Vue {
  @Prop({
    type: Array,
    required: false,
  })
  data!: [];
  $dayjs: any;
  serviceCycles = [
    {
      service_cycle_start: initDate('d'),
      service_cycle_end: initDate('d'),
    },
  ];
  aoiCountVal = 150;
  competeCountVal = 3;
  title = '企业服务管理';
  mallNameVal = '';
  mallData: any = [];
  tableSelect: any = [];
  tableSelectObj: any = {};
  loading = false;
  selectAll = true;
  mallCount = 0;
  columns = [
    {
      type: 'selection',
      align: 'center',
      width: 60,
    },
    {
      title: 'MALL-ID',
      key: 'mall_id',
    },
    {
      title: '项目名称',
      key: 'name',
      width: 130,
    },
    {
      title: '省份',
      key: 'province',
    },
    {
      title: '城市',
      key: 'city',
    },
    {
      title: '区县',
      key: 'district',
    },
  ];

  async searchClickHandler(): Promise<void> {
    this.loading = true;
    const params = {
      search_str: this.mallNameVal,
    };
    const res = await getMallBySearch(params);
    this.mallData = res.status === 0 ? res.data : [];
    this.loading = false;
  }
  tableClickHandler(row: any): void {
    if (row && (row as any).length) {
      this.tableSelectObj = [];
      row.forEach((item: any) => {
        const { mall_id } = item;
        this.tableSelectObj[mall_id] = item;
      });
      this.tableSelect = [];
      Object.values(this.tableSelectObj).forEach((item: any) => {
        this.tableSelect.push(item);
      });
    }
    this.mallCount = this.tableSelect.length;
  }
  deleteMallClickHandler(item: any, index: number) {
    this.tableSelect.splice(index, 1);
    this.mallCount = this.tableSelect.length;
    this.mallData.forEach((item1: any, index1: number) => {
      if (item1.mall_id === item.mall_id) {
        (this.$refs.table as any).$refs.tbody.objData[
          index1
        ]._isChecked = false;
      }
    });
  }
  startDateChangeHandler(dates: string, item: any): void {
    const date = this.$dayjs(dates).format('YYYY-MM-DD');
    if (item.service_cycle_end && date > item.service_cycle_end) {
      this.$Message.error('开始日期不能大于结束日期');
      item.service_cycle_start = '';
      return;
    }
    item.service_cycle_start = date;
  }

  endDateChangeHandler(dates: string, item: any): void {
    const date = this.$dayjs(dates).format('YYYY-MM-DD');
    if (item.service_cycle_start && date < item.service_cycle_start) {
      this.$Message.error('开始日期不能大于结束日期');
      item.service_cycle_end = '';
      return;
    }
    item.service_cycle_end = date;
  }

  cancelClickHandler(): void {
    const text = '取消后配置将不会生效，请确认或取消';
    this.$Modal.confirm({
      title: this.title,
      content: text,
      onOk: async () => {
        this.$emit('cancelChange');
      },
    });
  }
  confirmClickHandler(): void {
    if (this.tableSelect.length === 0) {
      const text = '未选择要添加的商场';
      this.$Message.warning(text);
      return;
    }
    const tempObj: any = {};
    for (const item of this.serviceCycles) {
      const { service_cycle_start, service_cycle_end } = item;
      if (!service_cycle_start || !service_cycle_end) {
        this.$Message.warning('请填写完整的服务周期');
        return;
      }
      if (tempObj[service_cycle_start] === service_cycle_end) {
        this.$Message.warning('服务周期重复，请检查');
        return;
      }
      tempObj[service_cycle_start] = service_cycle_end;
    }
    const data = this.processData();
    const text2 = '确认后，配置将会局部生效，等到外部提交后，配置会全部生效，如操作失误请取消，如正确请点击确认';
    this.$Modal.confirm({
      title: this.title,
      content: text2,
      onOk: async () => {
        this.$emit('confirmChange', data);
        this.$emit('cancelChange');
      },
    });
  }
  processData(): any {
    const data: any = [];
    const serviceCycles = this.serviceCycles.map((item: any) => `${this.$dayjs(item.service_cycle_start).format('YYYY/MM/DD')}-${this.$dayjs(item.service_cycle_end).format('YYYY/MM/DD')}`);
    const serviceCycle = serviceCycles.join('\n');
    const aoiInfo = `可添加${this.aoiCountVal},已添加0`;
    const competeInfo = `可添加${this.competeCountVal},已添加0`;
    this.tableSelect.forEach((item: any) => {
      const { mall_id, name, province, city, district } = item;
      const obj = {
        mall_id,
        name,
        province,
        city,
        district,
        service_cycle: serviceCycle,
        serviceCycles: this.serviceCycles,
        // service_cycle_start: this.service_cycle_start,
        // service_cycle_end: this.service_cycle_end,
        aoi_count: this.aoiCountVal,
        compete_count: this.competeCountVal,
        aoi_info: aoiInfo,
        compete_info: competeInfo,
      };
      // data[mall_id] = obj;
      data.push(obj);
    });
    return data;
  }
  addDate() {
    this.serviceCycles.push({
      service_cycle_start: initDate('d'),
      service_cycle_end: initDate('d'),
    });
  }
  deleteDate() {
    if (this.serviceCycles.length === 1) {
      return;
    }
    this.serviceCycles.pop();
  }
}
